import { computed, ref } from "vue";
import { useNeanic } from "@neanic/io";

const _updates = ref(0);

export const updates = computed(() => {
    return `${_updates.value}`;
});

export function update(reset = false)
{
    const v = _updates.value;
    _updates.value = reset === true ? 0 : v + 1;
}