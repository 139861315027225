import { NeanicClient, useNeanic } from "@neanic/io";

export function debounce(func: (...args: any[]) => void, delay: number) {
    let debounceTimer: number | undefined;
    return function(...args: any[]) {
        clearTimeout(debounceTimer);
        debounceTimer = window.setTimeout(() => {
            func(...args);
        }, delay);
    }
}

export function delay(ms: number){
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function hasWorkspace(neanic?: NeanicClient)
{
    neanic ||= useNeanic();
    return typeof neanic.session?.workspaceId === 'string';
}

export function hasServiceTypes(neanic?: NeanicClient)
{
    neanic ||= useNeanic();
    const st = neanic.session?.serviceTypes as string;
    return (typeof st === 'string' && st.trim().length > 0);
}

export function hasServiceType(serviceType: string, neanic?: NeanicClient): boolean{
    if (typeof serviceType !== 'string' || serviceType.length <= 0) return false;
    neanic ||= useNeanic();
    return neanic.session?.serviceTypes?.includes(serviceType) === true;
}

export function isRoot(neanic: NeanicClient)
{
    return neanic.hasClaim('$isRoot');
}

