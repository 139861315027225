<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router';
import { useNeanic } from '@neanic/io';
import { useSidebar } from '@neanic/io-vue';
import NavComponent from './nav.vue';
import SettingsComponent from '@/components/settings.vue';
import LogoComp from '@/components/logo.vue';
import { update } from '@/providers/workspace-updater';
import NotificationComp from '@/components/notification.vue';

const neanic = useNeanic();
const sb = useSidebar();
const docsUrl = import.meta.env.VITE_DOCS_URL;
const signoutUrl = `${import.meta.env.VITE_ACCOUNT_URL}`;

neanic.cache.watch('darkMode', darkMode => {
    document.documentElement.classList.toggle('dark', darkMode)
}, { immediate: true });

function toggleNav()
{
    if (sb.isVisible)
    {
        return sb.close();
    }

    sb.open('Proptexx', NavComponent);
}

async function showSettings(){
    const feedback = await sb.open('Settings', SettingsComponent)
    if (feedback) update();
}
</script>

<template>
    
    <header>

        <router-link to="/" class="logo">
            <LogoComp />
        </router-link>

        <nav>

            <button type="button" class="link md:hidden" @click="toggleNav">
                <i class="fa fa-rocket"></i>
            </button>

            <router-link :to="{name: 'inbox'}" class="max-md:hidden">
                <i class="fa fa-bell"></i>
            </router-link>

            <a :href="docsUrl" target="_blank" class="max-md:hidden">
                <i class="fa fa-info-circle"></i>
            </a>

            <a :href="signoutUrl" class="max-md:hidden">
                <i class="fa fa-sign-out"></i>
            </a>

            <button type="button" class="credentials max-md:hidden" @click="showSettings">
                BB
            </button>

        </nav>

    </header>

    <section class="page">

        <aside>
            <NavComponent class="main"></NavComponent>
        </aside>

        <main>
            <!-- <NotificationComp></NotificationComp> -->
            <router-view></router-view>
        </main>

    </section>

    <n-panel-view />

</template>
