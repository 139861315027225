import { useNeanic } from "@neanic/io";

export async function checkAuth(){

    let iterations = 0;
    const neanic = useNeanic();

    do {
        if (neanic.isAuthenticated) return true;
        await neanic.refreshAsync();
        iterations++;
    } while (iterations < 1 || neanic.isAuthenticated);

    return neanic.isAuthenticated;
}