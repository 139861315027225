import './index.pcss';
import '@fortawesome/fontawesome-free/css/all.css';
import { createNeanicVue } from '@neanic/io-vue';
import router from './router';
import { FormDefaults } from '@neanic/forms';
import { createVueForms } from '@neanic/forms-vue';
import App from './components/app.vue';
import { checkAuth } from './providers/check-auth';
import { LocalStorageCache } from '@neanic/io';

async function init()
{
    const { app, neanic } = createNeanicVue({
        rootComponent: App,
        neanicOptions: {
            apiKey: import.meta.env.VITE_AUTH_KEY,
            baseUrl: import.meta.env.VITE_AUTH_URL,
            commandUrl: import.meta.env.VITE_COMMAND_URL,
            queryUrl: import.meta.env.VITE_QUERY_URL,
            clientVersion: 'portal-version',
            authenticatedResolver: (client) => {
                return client.hasClaim('$accountId') || client.hasClaim('accountId');
            },
            cache: {
                defaultEngine: 'local',
                engines: {
                    local: new LocalStorageCache('pt'),
                }
            }
        }
    });

    const isAuth = await checkAuth();
    if (!isAuth){
        location.href = `${import.meta.env.VITE_ACCOUNT_URL}?redirectUrl=${location.href}`;
        return;
    }

    const forms = createVueForms({ formDefaultsType: FormDefaults });

    app.use(forms);
    app.use(router);
    app.mount('#app');
}

init();
